<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { mapMutations } from "vuex";

export default {
  name: "Init",
  props: ["lang"],
  methods: {
    ...mapMutations(["SET_MEGA24"]),
  },
  created() {
    let lang = this.lang;
    if (lang == undefined) {
      lang = process.env.VUE_APP_I18N_LOCALE;
    }
    localStorage.setItem("lang", lang);
    this.$i18n.locale = lang;

    let isMega24 = this.$route.query.mega24 ? this.$route.query.mega24 : 0;
    this.SET_MEGA24(isMega24);

    if (isMobile) {
      this.$router.push({ name: "NumberSearchMobile" });
    } else {
      this.$router.push({ name: "NumberSearch" });
    }
  },
};
</script>